<template>
    <div class="app flex-row align-items-center">
        <div class="row" style="padding: 0px !important; margin-top: -3px;margin-left: 0px;margin-right: 0px;margin-bottom: 0px;width: 100%">

            <div :class="{'col-md-9' : is_mobile == 'false', 'col-md-12' : is_mobile == 'true' }"  style="padding: 0px ;margin: 0px">
                <div class="jumbotron jumbotron-fluid" id="barra_titulo"  :style="'background-color:#e8eaf6;padding-top: 10px;padding-bottom:10px;margin:0px !important;z-index: 1010;-webkit-box-shadow: 2px 3px 14px -1px rgba(0,0,0,0.75);\n'+
'        -moz-box-shadow: 2px 3px 14px -1px rgba(0,0,0,0.75);\n'+
'        box-shadow: 2px 3px 14px -1px rgba(0,0,0,0.75); width: calc(' + this.ancho_video +'px + 1.1%)'">
                    <div class="container-fluid" style="margin-top: 6px;
            margin-bottom: 0px;">
                        <div class="row">
                            <div class="col-md-6">
                                <h4 v-if="canal != null" class="display-4" style="font-size:1.6rem !important;margin-left:10px">
                                    <mdb-badge v-if="canal.estado == 'ONLINE'" color="danger" m="r2">EN DIRECTO</mdb-badge>
                                    <mdb-badge v-if="canal.estado == 'OFFLINE'" style="background: gray !important;" m="r2">OFFLINE</mdb-badge>
                                     {{canal.nombre}}</h4>
                            </div>
                            <div class="col-md-6">


                                <div style="float:right">

                                    <div style="float:right;font-size: 1.4em;
                  padding-left: 10px;
                  padding-right: 10px;">

                                    </div>
                                    <div style="float:right;font-size: 1.4em;
                padding-left: 10px;
                padding-right: 10px;">{{marcador_likes}}
                                        <mdb-badge :pill="true" color="light-blue">
                                            <div @click="LikeEvento">
                                            <mdb-icon v-if="check_like == 0"  far icon="heart"></mdb-icon>
                                            <mdb-icon v-else fas icon="heart"></mdb-icon>
                                            </div>
                                        </mdb-badge>
                                    </div>


                                    <div v-if="canal != null" style="float:right;font-size: 1.4em;
                padding-left: 10px;
                padding-right: 10px;"><span style="padding-right: 10px;">{{marcador_views}}</span>
                                        <mdb-badge :pill="true" color="indigo">
                                            <mdb-icon far icon="eye"></mdb-icon>
                                        </mdb-badge>
                                    </div>

                                    <div v-if="canal != null" style="float:right;font-size: 1.4em;
                padding-left: 10px;
                padding-right: 10px;"><span style="padding-right: 10px;">{{genteOnline}}</span>
                                        <mdb-badge :pill="true" color="red">
                                            <i class="fas fa-users"></i>
                                        </mdb-badge>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>



                <center>
                <video autoplay id="video" v-bind:style="'padding-top:20px;width: ' + (ancho_video-0.20*ancho_video) + 'px !important'" ref="video" controls></video>
                </center>




            </div>


            <div v-if="this.is_mobile == 'false'" :style="'position: fixed;right: 0px;height: 100%;background: #c5cae9;margin-top: -5px;width:'+(ancho_sidebar - 16)+'px' ">
                <mdb-row class="" style="padding-left: 30px">
                    <b-row style="width: 100%;height: 200px; background: -moz-linear-gradient(top, white, rgb(226, 227, 234));">
                        <mdb-row class="mt-3 align-items-center justify-content-start" style="padding-left: 50px; padding-right: 50px;">
                            <img v-if="canal != null" v-bind:src="canal.delegacion.icono" v-bind:width="80" style="border-radius: 150px">
                            <h2 v-if="canal != null" style="margin-left: 15px">{{canal.delegacion.nombre}}</h2>
                        </mdb-row>

                    </b-row>
                    <b-row  class="justify-content-center" style="width: 100%;height: 40px; background: red">
                        <h4 style="margin-top: 5px;color: white"><b>PROGRAMACIÓN</b></h4>
                    </b-row>
                </mdb-row>

                <b-row class="mt-3 align-items-center justify-content-start" style="padding-left: 30px; padding-right: 30px;">
                    <b-card v-for="evento in this.eventos"  style="width: 100%;margin-bottom: 10px">
                        <div slot="header">
                            <span>
                                <mdb-badge v-if="evento.estado == 'EN_ESPERA'" pill color="purple" m="r2">PRÓXIMAMENTE</mdb-badge>
                                <mdb-badge v-if="evento.estado == 'EMITIENDO'" pill color="red" m="r2">EN DIRECTO</mdb-badge>
                                <b>{{evento.title}}</b>
                            </span>
                        </div>
                        <p>{{evento.descripcion}}</p>
                        <p><i class="far fa-clock"></i> Fecha/Hora : {{FechaSQLtoFecha(evento.inicio)}}</p>
                    </b-card>
                </b-row>


            </div>

            <div v-if="this.is_mobile == 'true'" style="background: rgb(249, 250, 255);padding-bottom: 90px;width: 100%">
                <mdb-row class="" style="padding-left: 30px">
                    <b-row style="width: 100%;height: 200px; background: -moz-linear-gradient(top, white, rgb(226, 227, 234));">
                        <mdb-row class="mt-3 align-items-center justify-content-start" style="padding-left: 50px; padding-right: 50px;">
                            <img v-if="canal != null" v-bind:src="canal.delegacion.icono" v-bind:width="80" style="border-radius: 150px">
                            <h2 v-if="canal != null" style="margin-left: 15px">{{canal.delegacion.nombre}}</h2>
                        </mdb-row>

                    </b-row>
                    <b-row  class="justify-content-center" style="width: 100%;height: 40px; background: red">
                        <h4 style="margin-top: 5px;color: white"><b>PROGRAMACIÓN</b></h4>
                    </b-row>
                </mdb-row>

                <b-row class="mt-3 align-items-center justify-content-start" style="padding-left: 30px; padding-right: 30px;">
                    <b-card v-for="evento in this.eventos"  style="width: 100%;margin-bottom: 10px">
                        <div slot="header">
                            <span>
                                <mdb-badge v-if="evento.estado == 'EN_ESPERA'" pill color="purple" m="r2">PRÓXIMAMENTE</mdb-badge>
                                <mdb-badge v-if="evento.estado == 'EMITIENDO'" pill color="red" m="r2">EN DIRECTO</mdb-badge>
                                <b>{{evento.title}}</b>
                            </span>
                        </div>
                        <p>{{evento.descripcion}}</p>
                        <p><i class="far fa-clock"></i> Fecha/Hora : {{FechaSQLtoFecha(evento.inicio)}}</p>
                    </b-card>
                </b-row>


            </div>


        </div>
    </div>


</template>




<script>

    import { mdbBreadcrumb,
        mdbBreadcrumbItem,
        mdbIcon,
        mdbRow,
        mdbContainer,
        mdbCard,
        mdbCardImage,
        mdbCardBody,
        mdbCardTitle,
        mdbCardText,
        mdbView,
        mdbBtn,
        mdbMask,
        mdbMediaImage,
        mdbBadge,
        mdbCardGroup,  } from 'mdbvue';
    import ApiService from '@/services/api';
    import { videoPlayer } from 'vue-video-player';




    export default {
        name: 'Home',
        components: {
            mdbBreadcrumb,
            mdbBreadcrumbItem,
            mdbContainer,
            mdbRow,
            mdbIcon,
            mdbCard,
            mdbCardImage,
            mdbCardBody,
            mdbCardTitle,
            mdbCardText,
            mdbView,
            mdbMask,
            mdbBtn,
            mdbBadge,
            mdbCardGroup,
            videoPlayer,
            mdbMediaImage,
        },
        mounted()
        {

            this.getEventoPrincipal();
            this.getEventosUpdated();
            this.getCanal();

        },
        data() {
            return{
                is_mobile:'false',
                marcador_online: 0,
                margin_ismobile: '',
                pantalla_vertical : false,
                genteOnline: 0,
                check_like: 0,
                marcador_views:0,
                marcador_likes:0,
                ancho_video: ((window.innerWidth/12)*9),
                ancho_barra: ((window.innerWidth/12)*9),
                ancho_sidebar : ((window.innerWidth/12)*3),
                eventos : null,
                evento_principal : null,
                canal: {
                    id : 0,
                    nombre: '',
                    delegacion : {
                        icono : '',
                        nombre : '',
                    },


                },
                responsive : true,
            }
        },

        methods: {

            LikeEvento()
            {
                console.log('like');
                if(this.evento_principal != null)
                {
                    if(this.check_like == 0) {
                        ApiService.AddLikeEvento(this.evento_principal.id).then(r => {
                            this.marcador_likes = r.data;
                            this.check_like =1;
                        });
                    }
                    else if(this.check_like == 1)
                    {
                        ApiService.QuitLikeEvento(this.evento_principal.id).then(r => {
                            this.marcador_likes = r.data;
                            this.check_like =0;
                        });

                    }
                }
            },

            FechaSQLtoFecha(value) {return  moment(String(value)).format('DD/MM/YYYY HH:mm:ss')},

            getEventoPrincipal: function () {
                var that = this;
                setInterval(function () {

                    ApiService.getEventoActivo(that.$route.params.id).then(response => {

                        that.evento_principal = response.data;
                        that.marcador_views = that.evento_principal.views;
                        that.marcador_likes = that.evento_principal.likes;

                        ApiService.getGenteOnlineStreaming().then( r => {
                            that.genteOnline = r.data;
                        });


                    }).catch(error => {
                        that.evento_principal = null;
                        that.marcador_likes = 0;
                        that.marcador_views = 0;
                        that.marcador_online = 0;
                    });
                }, 60000);
            },

            getCanal: function () {
                var that = this;
                setInterval(function () {

                        ApiService.VerCanal(that.$route.params.id).then(response => {

                            that.canal = response.data;
                        });
                }, 60000);
            },
            getEventosUpdated: function () {
                var that = this;
                setInterval(function () {

                    let ya_hay_directo = false;
                    if(that.eventos != null) {
                        that.eventos.forEach(e => {
                            if (e.estado == 'EMITIENDO') {
                                ya_hay_directo = true;
                            }
                        });
                    }


                    ApiService.getEventos(1,this.canal.id,'DESC',3,'EN_ESPERA','EMITIENDO').then( r => {
                        that.eventos = r.data;

                        //Refrescar si hay evento nuevo
                        that.eventos.forEach(e => {
                            if(e.estado == 'EMITIENDO' && !ya_hay_directo)
                            {
                                //code before the pause
                                setTimeout(function(){
                                    that.$router.go();
                                }, 48000);
                            }
                        })

                    });
                }, 60000);
            },
            onPlayerLoadeddata(e)
            {

                console.log('hola');
                if(!localStorage.getItem('tam_video')) {
                    if (this.is_mobile == 'false') {
                       // this.ancho_video = document.getElementById('vjs_video_1').clientWidth;
                        localStorage.setItem('tam_video', this.ancho_video);
                        // this.ancho_barra = ((window.innerWidth/12)*9);
                    } else {
                        // this.ancho_barra = window.innerWidth;
                    }


                    console.log('EJECUTADO EVENTO ONPLAYERLOADDATA');
                    console.log('ancho video : ' + this.ancho_video);
                    console.log('ancho barra : ' + this.ancho_barra);
                }
            },

        },
        created(){

            ApiService.VerCanal(this.$route.params.id).then(response => {
                console.log('canal');

                this.canal = response.data;
                console.log(this.canal);

                ApiService.getEventos(1,this.canal.id,'DESC',3,'EN_ESPERA','EMITIENDO').then( r => {
                    this.eventos = r.data.data;
                    console.log('eventos');
                    console.log(this.eventos);
                });

                ApiService.getEventoActivo(this.canal.id).then(response => {
                   console.log('evento activo : ');
                   this.evento_principal = response.data;
                   this.marcador_likes = this.evento_principal.likes;
                   this.marcador_views = this.evento_principal.views;

                    ApiService.getGenteOnlineStreaming().then( r => {
                        this.genteOnline = r.data;
                    });

                    if(this.evento_principal != null) {
                        ApiService.checkLikeEvento(this.evento_principal.id).then(r => {
                            console.log('check like : ');
                            this.check_like = r.data;
                            console.log(this.check_like);
                        });

                        ApiService.ViewEvento(this.evento_principal.id).then(res => {

                        });
                    }
                   console.log(this.evento_principal);
                }).catch(error => {
                    this.marcador_likes = 0;
                    this.genteOnline = 0;
                    this.marcador_views = 0;
                });





                ApiService.isMobile().then(response => {
                    console.log('Ejecutando IsMobile ...');
                    console.log(response);
                    this.is_mobile = response.data.is_mobile.toString();

                    if(this.is_mobile == 'true' && this.canal.estado == 'ONLINE')
                    {
                        if(this.is_mobile == 'true')
                        {
                            this.ancho_video = window.innerWidth;
                            this.margin_ismobile = ';margin-top:-15px';
                        }



                    }
                    if(localStorage.getItem('is_mobile'))
                    {

                        this.is_mobile = localStorage.getItem('is_mobile');
                        console.log('leyendo is_mobile : ' + this.is_mobile );

                    }

                    else {
                        console.log('asignando is_mobile = ' + this.is_mobile);
                        localStorage.setItem('is_mobile', this.is_mobile);
                        if(this.is_mobile == true) {
                            localStorage.setItem('res_video', '360p');
                        }
                        else {
                            localStorage.setItem('res_video', '720p');
                        }
                        this.$forceUpdate();
                    }

                    console.log('Recalculando tamaños : ');
                    if (localStorage.getItem('tam_video')) {
                        console.log('tam video : ');

                       // this.ancho_video = localStorage.getItem('tam_video');
                        console.log(this.ancho_video);
                    } else {
                        //Generar TAMVideo y recargar
                        this.screenHeight = window.innerHeight;
                        this.screenWidth = window.innerWidth;
                        console.log('Screen height' + this.screenHeight);
                        console.log('Screen Width' + this.screenWidth);

                        if(this.is_mobile == 'false') {
                            // this.ancho_barra = ((window.innerWidth/12)*9);
                        }
                        else
                        {
                            this.ancho_barra = window.innerWidth;
                        }
                        localStorage.setItem('tam_video', this.ancho_video);
                        this.$forceUpdate();

                    }
                });



                console.log('montando');


                    if(Hls.isSupported()) {
                        console.log('is suported');
                        var video = document.getElementById('video');
                        var hls = new Hls();
                        hls.loadSource(this.canal.ruta);
                        hls.attachMedia(video);
                        hls.on(Hls.Events.MANIFEST_PARSED,function() {
                            video.play();
                        });
                    }
                    // hls.js is not supported on platforms that do not have Media Source Extensions (MSE) enabled.
                    // When the browser has built-in HLS support (check using `canPlayType`), we can provide an HLS manifest (i.e. .m3u8 URL) directly to the video element throught the `src` property.
                    // This is using the built-in support of the plain video element, without using hls.js.
                    else if (video.canPlayType('application/vnd.apple.mpegurl')) {
                        console.log('is suported 2');
                        video.src = this.canal.ruta;
                        video.addEventListener('canplay',function() {
                            video.play();
                        });
                    }
                    else
                    {
                        console.log('is not supported');
                    }



            });





            /*if(this.is_mobile == 'false') {
                console.log('ejecuntado seccion mobile false');
                if (localStorage.getItem('tam_video')) {
                    console.log('tam video : ');

                    this.ancho_video = localStorage.getItem('tam_video');
                    console.log(this.ancho_video);
                } else {
                    //Generar TAMVideo y recargar
                    this.screenHeight = window.innerHeight;
                    this.screenWidth = window.innerWidth;
                    console.log('Screen height' + this.screenHeight);
                    console.log('Screen Width' + this.screenWidth);

                    this.ancho_barra = window.innerWidth - this.ancho_video - 15;
                    localStorage.setItem('tam_video', this.ancho_video);
                    this.$forceUpdate();

                }


                this.screenHeight = window.innerHeight;
                this.screenWidth = window.innerWidth;
                console.log('Screen height' + this.screenHeight);
                console.log('Screen Width' + this.screenWidth);

                this.ancho_barra = window.innerWidth - this.ancho_video - 15;
            }*/






        },

        computed: {

        }

    }


</script>

<style>
    html {
        scroll-behavior: smooth;
    }

</style>
